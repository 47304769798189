import VueTypes from 'vue-types'
import clickOutside from 'src/directives/lib/clickOutside'

export default {
  directives: {
    clickOutside
  },
  props: {
    options: VueTypes.any, // { symbol, title }
    value: Object,
    theme: VueTypes.oneOf(['dark', 'light']).def('light'),
    type: VueTypes.oneOf(['icon', 'text']).def('text')
  },
  data () {
    return {
      optionsEnabled: false
    }
  },
  computed: {
    title () {
      return this.value && this.value.title
    }
  },
  methods: {
    closeOptions () {
      this.optionsEnabled = false
    },
    changeSelected (value) {
      this.optionsEnabled = false
      this.$emit('change', value.symbol)
    },
    openOptions () {
      this.optionsEnabled = !this.optionsEnabled
    }
  }
}
