// import VueTypes from 'vue-types'
import { mapGetters } from 'vuex'

export default {
  props: {
    link: String, // '/private/example' || 'http://www.example.com'
    mode: String,
    titleIsHtml: {
      type: Boolean,
      default: false
    }, // f.e. <p>...</p>
    title: String,
    forceOuter: Boolean
  },
  computed: {
    ...mapGetters({
      config: 'config/config'
    }),
    processedLink () {
      try {
        const url = new URL(this.link)
        if (!this.forceOuter && url.origin === this.config.self.url) {
          return {
            outer: false,
            href: url.pathname
          }
        }
        return {
          outer: true,
          href: url.href
        }
      } catch (e) {
        return {
          outer: false,
          href: this.link
        }
      }
    }
  }
}
