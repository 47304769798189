import { render, staticRenderFns } from "./LinkComponent.htm?vue&type=template&id=aa7297e0&scoped=true&"
import script from "./LinkComponent.js?vue&type=script&lang=js&"
export * from "./LinkComponent.js?vue&type=script&lang=js&"
import style0 from "./LinkComponent.scss?vue&type=style&index=0&id=aa7297e0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa7297e0",
  null
  
)

export default component.exports