import { mapGetters, mapState } from 'vuex'
import { CachedImageModel } from 'src/models'
import TxSimpleSelect from '@/components/lib/TxSimpleSelect/TxSimpleSelect.vue'
import LinkComponent from '@/components/lib/LinkComponent/LinkComponent.vue'
export default {
  components: {
    TxSimpleSelect,
    LinkComponent
  },
  computed: {
    ...mapState({
      companyContacts: state => state.public.companyContacts,
      papers: state => state.public.papers,
      footerCoins: state => state.public.footerCoins,
      ourProducts: state => state.public.ourProducts,
      socials: state => state.public.socials
    }),
    ...mapGetters({
      config: 'config/config'
    }),
    initialLanguage () {
      return this.config.languages.find(l => l.symbol === this.language)
    },
    homePageUrl () {
      return this.config.self.url + this.getLanguageUrlPathPrefix()
    },
    sortedSocials () {
      const socials = [...this.socials]
      return socials
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .filter(socials => socials.showInFooter)
    }
  },
  methods: {
    createCompanyUrl (url) {
      if (url.startsWith('/')) {
        return this.getLanguageUrlPathPrefix() + url
      }
      return url
    },
    getImageUrl (image) {
      const imageModel = CachedImageModel.fromServerModel(image, {
        baseUrl: this.config.publicService.imageUrl
      })
      return imageModel.url
    }
  }
}
