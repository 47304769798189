export default {
  bind (el, binding, vnode) {
    el.clickOutsideListener = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value.bind(vnode.context)(event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideListener)
  },
  unbind (el, binding, vnode) {
    if (el.clickOutsideListener) {
      document.body.removeEventListener('click', el.clickOutsideListener)
      el.clickOutsideListener = null
    }
  }
}
