import { mapGetters, mapState, mapActions } from 'vuex'
import TxButton from '@/components/lib/TxButton/TxButton.vue'
import TxSimpleSelect from '@/components/lib/TxSimpleSelect/TxSimpleSelect.vue'
import LinkComponent from '@/components/lib/LinkComponent/LinkComponent.vue'

export default {
  components: {
    TxButton,
    TxSimpleSelect,
    LinkComponent
  },
  data () {
    return {
      showMobileMenu: false,
      isBusySignUp: false,
      isBusySignIn: false,
      isMobile: false
    }
  },
  mounted () {
    if (this.windowSize.width <= 600) {
      this.isMobile = true
    }
  },
  computed: {
    ...mapGetters({
      config: 'config/config'
    }),
    ...mapState({
      passport: state => state.passport?.user,
      currencies: state => state.currencies.list,
      windowSize: state => state.ui.windowSize
    }),
    currency () {
      return this.currencies.value.filter(e => e.showOnCoinPages)[0].symbol
    },
    navItems () {
      return [
        {
          href: `/buy-${this.currency.toLowerCase()}`,
          title: this.i18nTemplate('mainpageTopNavBuyTemplate', { value: this.currency }),
          enabled: true
        },
        {
          href: '/otc',
          title: this.i18nSection('mainpageTopNavOTCTitle'),
          enabled: true
        },
        {
          href: '/affiliate-program',
          title: this.i18nSection('mainpageTopNavReferralProgramTitle'),
          enabled: true
        },
        {
          href: '/about-us',
          title: this.i18nSection('mainpageTopNavAboutUsTitle'),
          enabled: true
        },
        {
          href: '/faq',
          title: this.i18nSection('mainpageTopNavFAQTitle'),
          enabled: true
        },
        {
          href: '/blog',
          title: this.i18nSection('mainpageTopNavBlogTitle'),
          enabled: true
        }
      ]
    },
    initialLanguage () {
      return this.config.languages.find(l => l.symbol === this.language)
    },
    homePageLink () {
      const res = this.getLanguageUrlPathPrefix()
      if (res) {
        return this.getLanguageUrlPathPrefix()
      }
      return `/${this.getLanguageUrlPathPrefix()}`
    },
    pageTabs () {
      const tabs = []
      if (this.passport) {
        tabs.push({ name: 'deposit', label: { title: 'depositTitle' }, to: 'private/simple-mode/balances', icon: 'plus' })
        tabs.push({ name: 'trading', label: { title: 'convertMenuTitle' }, to: `private/convert${this.$route.query.coin ? `?from=${this.$route.query.coin}` : ''}`, icon: 'convert' })
        tabs.push({ name: 'trade', label: { title: 'apiKeyTradingPermissionTitle' }, to: 'private/trading/TIME', icon: 'setup' })
      } else {
        tabs.push({ name: 'deposit', label: { title: 'depositTitle' }, to: 'private/convert', icon: 'plus' })
        tabs.push({ name: 'trading', label: { title: 'convertMenuTitle' }, to: 'private/convert', icon: 'convert' })
        tabs.push({ name: 'trade', label: { title: 'apiKeyTradingPermissionTitle' }, to: 'private/trading/TIME', icon: 'setup' })
      }
      return tabs
    },
    pageActions () {
      const actions = []
      actions.push({ name: 'balances', label: { title: 'balancesTitle' }, to: 'private/simple-mode/balances', icon: 'wallet' })
      actions.push({ name: 'history', label: { title: 'historyMenuTitle' }, to: 'private/transactions', icon: 'new-calendar' })
      actions.push({ name: 'bonus', label: { title: 'bonusFundsTitle' }, to: 'private/balances/current-bonus', icon: 'gift' })
      actions.push({ name: 'account', label: { title: 'profileNotification' }, to: 'private/user/account', icon: 'user' })
      return actions
    }
  },
  methods: {
    ...mapActions({
      authInitiate: 'auth/initiate',
      signout: 'passport/signout'
    }),
    async handleLogout () {
      await this.signout()
      this.showMobileMenu = false
    },
    openMenu () {
      this.showMobileMenu = !this.showMobileMenu
      const root = document.getElementById('html')
      root.classList.toggle('noscroll')
    },
    createLocalizedLink (url) {
      if (url.startsWith('/private')) {
        return url
      }
      return this.getLanguageUrlPathPrefix() + url
    },
    async handleSignUp () {
      this.isBusySignUp = true
      await this.authInitiate({ mode: 'signup' })
    },
    async handleSignIn () {
      this.isBusySignIn = true
      await this.authInitiate()
    }
  }
}
